<template>
  <v-row class="d-flex align-center pa-0 ma-0 login-row">
    <v-col cols="12" md="6" class="pa-0 d-none d-sm-none d-md-flex login-left fill-height">
      <div class="wrapper d-flex flex-column" style="z-index:1">
        <v-row>
          <img
            src="@/assets/login/gns-software-logo-white.svg"
            alt="GNS Software Logo"
            width="250px"
          />
        </v-row>
        <v-row class="d-flex align-center">
          <v-col cols="auto">
            <h1>
              Bienvenidos
              <br />a Recibos Digitales
            </h1>
            <h3>Gestioná tus recibos de sueldo en un sólo lugar.</h3>
          </v-col>
        </v-row>
      </div>
      <div class="overlay"></div>
    </v-col>
    <v-col cols="12" md="6" class="pa-0 white login-right" v-show="!showreset">
      <v-row
        class="wrapper d-flex flex-column justify-center px-8 px-sm-10"
        style="max-height:1000px;"
      >
        <div>
          <img
            src="@/assets/login/gns-software-logo.svg"
            alt="GNS Software Logo"
            class="d-flex d-md-none mb-6 logo"
          />
          <h1 class="inicio-sesion">
            Inicio de sesión (Administrador)
            <div class="left-color-bar"></div>
          </h1>
        </div>

        <v-form autocomplete="off" class="login-form mt-10 mb-4">
          <v-text-field
            label="Documento de identidad"
            v-model="login.username"
            :rules="login.usernameRules"
            name="doc"
            required
            v-on:keyup.enter="processLogin()"
            v-on:blur="loadEmpresas()"
            class="login-input"
            dense
          />
          <v-text-field
            label="Nombre de usuario"
            v-model="login.username"
            :rules="login.usernameRules"
            name="username"
            required
            v-on:keyup.enter="processLogin()"
            v-on:blur="loadEmpresas()"
            class="login-input"
            dense
          />

          <v-select
            ref="idEmpresa"
            v-model="login.empresa"
            :loading="login.empresasLoading"
            :items="login.empresas"
            color="primary"
            label="Empresa"
            item-text="Nombre"
            item-value="CodigoEmp"
            :rules="login.empresaRules"
            class="login-input"
          ></v-select>

          <v-text-field
            v-model="login.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="login.passwordRules"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            label="Contraseña"
            counter
            @click:append="showPassword = !showPassword"
            v-on:keyup.enter="processLogin()"
            class="login-input"
          ></v-text-field>

          <v-row>
            <v-col cols="12" sm="12" class="text-left">
              <a href="javascript:void(0)" v-on:click="showreset = true">Te olvidaste la contraseña?</a>
            </v-col>
          </v-row>
        </v-form>

        <div class="d-flex flex-flow flex-wrap justify-space-between">
          <v-row>
            <v-col cols="3" xs="3" sm="3" class="text-left">
              <a href="javascript:void(0)" @click="goto('login')">Ir al login</a>
            </v-col>
            <v-col cols="9" xs="9" sm="9" class="text-right">
              <v-btn
                v-on:keyup.enter="processLogin()"
                v-on:click="processLogin()"
                class="btn-ingresar"
              >Ingresar</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-col>

    <v-col cols="12" md="6" class="pa-0 white" v-show="showreset">
      <v-row class="wrapper d-flex flex-column justify-center pa-10">
        <img
          src="@/assets/login/gns-software-logo.svg"
          alt="GNS Software Logo"
          width="250px"
          class="d-flex d-md-none mb-6"
        />
        <h1 class="text-left secondary--text texto-bienvenida">
          <v-icon class="mr-5 pb-1">fas fa-lock</v-icon>Recuperar contraseña
        </h1>
        <p>Para iniciar el proceso de recuperación de contraseña ingrese su email</p>
        <v-form autocomplete="off" class="mt-4">
          <v-text-field
            prepend-icon="mdi-account"
            label="Usuario o correo electrónico"
            v-model="reset.username"
            :rules="reset.usernameRules"
            name="username"
            required
            v-on:keyup.enter="processReset()"
          />
        </v-form>

        <div class="d-flex flex-flow flex-wrap justify-space-evenly mt-10">
          <v-btn
            v-on:keyup.enter="processReset()"
            v-on:click="processReset()"
            color="primary"
            rounded
            block
            large
          >Recuperar</v-btn>
        </div>

        <div class="d-flex flex-flow flex-wrap justify-space-between mt-10">
          <a
            href="javascript:void(0)"
            v-on:click="showreset=false"
            class="secondary--text"
            style="text-decoration:none"
          >
            <v-icon class="mr-5">arrow_back</v-icon>Volver
          </a>
        </div>
      </v-row>
    </v-col>

    <v-snackbar
      v-model="$store.state.snackbar.show"
      :color="$store.state.snackbar.color"
      :timeout="$store.state.snackbar.timeout"
    ><div v-html="$store.state.snackbar.message"></div></v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Login admin",
  components: {},

  data() {
    return {
      showreset: false,
      loading: false,
      redirect: undefined,
      showPassword: false,
      valid: false,

      login: {
        doc: "",
        docRules: [(v) => !!v || "El documento es requerido"],

        username: "",
        usernameRules: [(v) => !!v || "El usuario es requerido"],

        empresa: "",
        empresaRules: [(v) => !!v || "La empresa es requerida"],
        empresasLoading: false,
        empresas: [],

        password: "",
        passwordRules: [(v) => !!v || "El password es requerido"],
      },

      reset: {
        username: "",
        usernameRules: [(v) => !!v || "El usuario es requerido"],

        empresa: "",
        empresaRules: [(v) => !!v || "La empresa es requerida"],
      },
    };
  },

  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(["local_api_url"]),
  },

  methods: {
    //this.local_api_url

    processLogin() {
      // const empresa = this.login.empresas.find(
      //   (e) => e.CodigoEmp == this.login.empresa
      // );
      let credentials = {
        username: this.login.username,
        empresa: this.login.empresa,
        password: this.login.password
      };

      this.$store
        .dispatch("user/login", {
          url: process.env.VUE_APP_MIX_BASE_API,
          userInfo: credentials,
        })
        .then(() => {
          this.$store.commit("user/SET_COMPANIES", this.login.empresas);
          this.$router.push({ path: this.redirect || "/" });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.valid = false;
          this.showError(error)
        });
    },

    processReset() {},

    async loadEmpresas() {
      this.login.empresasLoading = true;
      try {
        const response = await axios.get(
          process.env.VUE_APP_MIX_BASE_API + "/datos-empresas",
          { params: { doc: this.login.username } }
        );
        this.login.empresas = response.data;
      } catch(error) {
        this.showError(error)
      }
      this.login.empresasLoading = false;
    },

    goto(name) {
      this.$router.push({ name: name })
    },

    showError(error) {
      let error_data = error.response.data;
      let message = null;
      if (
        Object.prototype.hasOwnProperty.call(error_data, "code") &&
        Object.prototype.hasOwnProperty.call(error_data, "message")
      ) {
        message =
          error_data.message +
          (error_data.code != 1000 ? " | Código: " + error_data.code : "");
      } else if (error_data.errors) {
        let errors_messages = error_data.errors;
        message = "<ul>";
        for (let m in errors_messages) {
          message += "<li>" + errors_messages[m].join(" - ") + "</li>";
        }
        message += "</ul>";
      } else if (error_data.error) {
        message = error_data.error;
      } else {
        message =
          "Ha ocurrido un error en el servidor. Intenta conectarte nuevamente.";
      }

      // Mostrar un mensaje
      this.$store.commit("setSnackBar", {
        message: message,
        show: true,
        color: "#cc0605",
      });    
    }
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 550px;
  height: 100%;
  margin: 0 auto;
}

.login-left {
  background-image: url("../../assets/login/login-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 50%;
  color: white;
  position: relative;
}

.login-left h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 97.3%;
  letter-spacing: -0.0243em;
  color: #ffffff;
}

.login-left h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 107.3%;
  letter-spacing: -0.0143em;
  color: #ffffff;
  max-width: 350px;
}

.inicio-sesion {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0143em;
  color: #2d2d2d;
  position: relative;
}

.login-right {
  position: relative;
  background-color: white !important;
  height: 100%;
  max-height: 650px;
}

.login-right .logo {
  width: 250px;
}
.login-right .left-color-bar {
  position: fixed;
  left: 50%;
  width: 14px;
  height: 170px;
  background-color: #ff3301;
}
.overlay {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) -4.63%,
    rgba(196, 196, 196, 0) 103.53%
  );
  opacity: 0.7;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.btn-ingresar {
  background-color: #ff3301 !important;
  border-radius: 28px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff !important;
  box-shadow: none;
  width: 260px;
  height: 56px !important;
  text-transform: capitalize !important;
  /*margin-top: 2rem;*/
}

a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #000000 !important;
}

.login-right .v-text-field .v-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #333333;
}

.login-right .v-input input {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  color: #757575;
  margin: 10px auto;
}

.login-row{
  background: white; 
  height: 100%;
}

@media (max-height: 700px) and (max-width: 991px) {
  .texto-bienvenida {
    display: none;
  }
}

@media (max-width: 960px) {
  .login-right .left-color-bar {
    left: 0;
  }
}

@media (max-width: 700px) {
  .login-right .left-color-bar {
    width: 6px;
    height: 80px;
  }
  .btn-ingresar {
    margin-top: 0 !important;
  }
}

@media (max-height: 850px) and (max-width: 991px) {
  .btn-ingresar {
    height: min-content !important;
    width: 100% !important;
    padding: 0.8rem !important;
  }
  .login-right .logo {
    max-width: 170px;
    max-height: 44px;
  }
  .inicio-sesion {
    font-size: 24px;
    line-height: 0;
  }
  .login-right .v-input input {
    margin: 0;
  }
  .btn-ingresar {
    margin-top: 0;
  }
  .login-right a {
    font-size: 14px;
  }
  .login-right {
    max-height: 500px;
    overflow: hidden !important;
    padding: 0;
  }
  .login-right .v-input {
    padding: 0 !important;
  }
  .login-righ > .row {
    padding: 4rem !important;
  }
  .login-row{
    display: flex !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0;
  }

  @media (max-height: 550px) and (max-width: 991px) {
    .login-form {
      margin-top: 2rem !important;
      margin-bottom: 0rem !important;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
  @media (max-height: 500px) and (max-width: 991px) {
    .login-form {
      margin-top: 1rem !important;
      margin-bottom: 0rem !important;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
    .login-row{
      display: block !important;
    }
  }
}

.v-snack--bottom {
  margin: auto 0;
}
</style>
